/* --Common Color -- */
$black: #000;
$white: #FFF;

/* --Custom Colors -- */
// E0E1E0

/* Primary Colors */
$mlBlue1: #E8F3F9;
$mlBlue2: #007DB1;
$mlBlue3: #005FCC;
$mlBlue4: #005BB8;
$mlLightBlue: #E4F1F9;
$mlDarkBlue: #005CB9;
$mlDarkBlue2: #0163A8;
$mlDarkBlue3: #023764;
$mlDarkBlue4: #37597B;
$mlGreen: #1A5B1C;
$mlGreen2: #00802F;
$mlWhite: #FFF;
$mlBlack: #000;
$mlBlack2: #2b2b2b;
$mlBlack3: #000000DB;

/* Secondary Colors */
$mlDarkestGray: #333;
$mlDarkestGray2: #424242;
$mlDarkGray: #75787B;
$mlDarkGray2: #696969;
$mlDarkGray3: #989898;
$mlDarkGray4: #6e7b8c;
$mlMediumGray: #A7A8AA;
$mlMediumGray2: #CCCCCC;
$mlGray: #D9D9D6;
$mlGray2: #BEBEBE;
$mlLightGray: #F2F2F2;
$mlBerry: #A30134;
$mlPurple: #5F259F;
$mlYellow: #FFC600;
$mlTeal: #00ACA0;
$mlNotice: #FFE5DC;
$mlInfo: #D1EDF2;
$mlRed: #FF671D;
$mlAlertRed: #DB1818;
$mlOrange: #fd7e14;
$mlGreen: #198754;
$mlLightGreen: #76bd64;
$mlIndigo: #6610f2;
$mlViolet: #0f1618;

$mlNewKeyword: $mlGreen;

$unreadAlertBgColor: #E7F2F9;
$bgColor: #EEF1F5;

$mlBlueNavHover: $mlBlue1;
$mlBlueTooltipBorder: $mlDarkBlue;
$mlFocusBorder: #005FCC;
$mlFocusBorder2: #629BF9;
$mlLightFocusBolder: #4bb3e7;
$mlFocusOutline: #254E76;
$mlDragging: #FFE5DC;

/* links */
$link-color: $mlBlue2;
$link-decoration: underline;
$link-hover-color: $mlBlue2;
$link-hover-decoration: none;

/* fonts */
$mlHeaderFont: 'Hind', Arial, sans-serif;
$mlBrandFont: 'Lato', sans-serif;
$mlBodyFont: Arial, Helvetica, sans-serif;
$mlDarkFont: $mlDarkestGray;
$mlFontWeightBold: 600;

/* tooltip z-index */
$mlTooltipZIndex: 998;

$bootstrapShadowSm: 0 0 0.375rem #2680EB;
$bootstrapShadowSmGray: 0 0 0.375rem $mlDarkestGray;

/* bootstrap main */
$body-color: $mlDarkFont;
$body-bg: #fff;
$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;
$link-hover-color: $mlBlue2;

/* bootstrap card */
$card-border-width: 0;
$card-cap-bg: $mlWhite;
$card-spacer-x: 2rem;
$card-spacer-y: 2rem;

/* bootstrap nav */
$nav-link-padding-y: 1rem;
$nav-link-padding-x: 1.5rem;
$nav-tabs-border-color: #989898;
$nav-tabs-link-active-color: $mlBlack;
$nav-tabs-link-active-border-color: #989898 #989898 $body-bg;
$nav-pills-link-active-color: $mlDarkestGray;
$nav-pills-link-active-bg: $mlLightGray;
$nav-pills-border-radius: 5px;

/* bootstrap table */
$table-accent-bg: #E5F4FB;
$table-cell-padding-sm: 0.3rem 3rem;
$mlHomeRowMaxWidth: 68rem;
